<template>
  <div>
    <h1 class="text-h4">Управление скидками</h1>
    <v-divider class="my-2"></v-divider>
    <v-form ref="form" v-model="form.valid" :disabled="busy" @submit.prevent="submitForm">
      <v-card>
        <v-card-title>Новая программа</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="form.data.promotion"
            label="Название"
            counter="150"
            hint="Название программы скидок"
            :rules="form.rule.promotion"
          />
          <v-select
            v-model="form.data.discountTarget"
            class="mt-2"
            label="Цель скидки"
            :items="discountTargetOptions"
            :rules="form.rule.discountTarget"
            return-object
          />
          <v-row dense>
            <v-col>
              <drop-down-date-picker
                v-model="form.data.dateStart"
                label="Дата начала"
                :rules="form.rule.dateStart"
                :max="form.data.dateEnd"
              />
            </v-col>
            <v-col>
              <drop-down-date-picker
                v-model="form.data.dateEnd"
                label="Дата конца"
                :rules="form.rule.dateEnd"
                :min="form.data.dateStart"
              />
            </v-col>
          </v-row>
          <v-select
            v-model="form.data.discountValueType"
            class="mt-2"
            label="Тип скидки"
            :items="discountValueTypeOptions"
            :rules="form.rule.discountValueType"
            return-object
            @change="revalidateForm"
          />
          <v-text-field
            v-model="form.data.discountValue"
            label="Значение"
            counter="12"
            :rules="form.rule.discountValue"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :to="{name: 'discount.list'}">Отмена</v-btn>
          <v-btn color="primary" :disabled="!form.valid" type="submit">Отправить</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { dashboardPageCommonAttributes } from '@/mixins/DashboardPageCommonAttributes'
import { ruleNumeric, ruleRequired } from '@/utils/FormRules'
import { mapActions, mapState } from 'vuex'
import ToastService from '@/service/ToastService'
import DropDownDatePicker from '@/components/ui/DropDownDatePicker.vue'
import DiscountUpdateForm from '@/model/discount/DiscountUpdateForm'

export default {
  ...dashboardPageCommonAttributes,
  name: 'DiscountCreatePage',
  components: { DropDownDatePicker },
  data() {
    return {
      form: {
        valid: true,
        data: {
          promotion: '',
          discountTarget: null,
          dateStart: null,
          dateEnd: null,
          discountValueType: null,
          discountValue: null
        },
        rule: {
          promotion: [ruleRequired()],
          discountTarget: [ruleRequired()],
          dateStart: [ruleRequired()],
          dateEnd: [ruleRequired()],
          discountValueType: [ruleRequired()],
          discountValue: [ruleRequired(), ruleNumeric(), (value) => {
            if (this.form.data.discountValueType && this.form.data.discountValueType.value === 'Rate') {
              if (this.form.data.discountValue <= 0 || this.form.data.discountValue > 100) {
                return 'Значение должно быть больше 0 но не более 100'
              }
            }

            return true
          }]
        }
      },
      discountTargetOptions: [
        { text: 'Товары в заказе', value: 'OrderLinesAmount' },
        { text: 'Доставка', value: 'ShippingAmount' }
      ],
      discountValueTypeOptions: [
        { text: 'Фиксированная сумма', value: 'Amount' },
        { text: 'Процент', value: 'Rate' }
      ]
    }
  },
  computed: {
    ...mapState('discount_create', ['busy'])
  },
  methods: {
    ...mapActions('discount_create', ['createDiscount']),
    submitForm() {
      const form = {
        ...DiscountUpdateForm.create(this.form.data),
        discountTarget: this.form.data.discountTarget.value,
        discountValueType: this.form.data.discountValueType.value
      }

      this.createDiscount({ form })
        .then(() => this.$router.push({ name: 'discount.list' }))
        .catch((error) => {
          console.warn(error)
          ToastService.error('Что-то пошло не так')
        })
    },
    revalidateForm() {
      this.$refs.form.validate()
    }
  }
}
</script>

<style scoped>

</style>
